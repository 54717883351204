/**
 * @deprecated
 *
 * While we can (and should) still use this for existing analytics plugins,
 * we should eventually figure out a way to to move these events into Segment.
 *
 */
export const DeprecatedEventType = {
  ADD_LAWN_FUNNEL_ADD_ON_TO_CART: 'Add Lawn Funnel Add-On to Cart',
  ADD_TO_CART: 'Add to Cart (Universal)',
  ADD_PEST_FUNNEL_SUBSCRIPTIONS_TO_CART:
    'Add Pest Funnel Subscription(s) to Cart',
  ANSWER_LAWN_FUNNEL_QUESTIONNAIRE: 'Answer Lawn Funnel Questionnaire',
  CCPA_OPT_OUT: 'CCPA Opt Out',
  CHECKOUT_STEP: 'Checkout Step',
  CUSTOM: 'Custom',
  EXPERIMENT: 'Experiment',
  HOMEPAGE_BANNER_CLICK: 'Homepage Banner Click',
  LAWN_FUNNEL_STEP: 'Lawn Plan Funnel Step',
  LEAD_STORE: 'Lead Store', // triggered when a user creates an account through Store Landing Page or any PDP
  LEAD_LAWN_FUNNEL: 'Lead Lawn Funnel',
  LEAD_PEST_FUNNEL: 'Lead Pest Funnel',
  LEAD_BROWN_SPOT: 'Lead Brown Spot',
  PAGE_VIEW: 'Page View',
  PLAY_VIDEO: 'Play Video',
  PURCHASE: 'Purchase',
  PURCHASE_ADD_ON: 'Purchase Add-On',
  PURCHASE_ONLY_ADD_ON: 'Purchase Add-On Only',
  REGISTER: 'Register (Universal)',
  SET_USER: 'Set User',
  SIGN_IN: 'Sign In',
  START_CHECKOUT: 'Start Checkout (Universal)',
  START_CHECKOUT_LAWN_FUNNEL: 'Start Lawn Funnel Checkout',
  START_CHECKOUT_PEST_FUNNEL: 'Start Pest Funnel Checkout',
  START_LAWN_FUNNEL: 'Start Lawn Funnel',
  START_PEST_FUNNEL: 'Start Pest Funnel',
  SUBSCRIBE_LAWN_FUNNEL: 'Subscribe Lawn Funnel',
  SUBSCRIBE_PEST_FUNNEL: 'Subscribe Pest Funnel',
  VIEW_CONTENT: 'View Content',
};

/**
 * Event names should take the form of `{ NOUN_VERBED: 'Noun Verbed' }`
 * where the noun is the object of the event, and the verb is the (past tense) action.
 */
export const EventType = {
  ACCOUNT_CREATED: 'Account Created',
  ACCOUNT_UPDATED: 'Account Updated',
  ADDRESS_CTA_CLICKED: 'Address CTA Clicked',
  ADDRESS_ENTERED: 'Address Entered',
  ARTICLE_CLICKED: 'Article Clicked',
  ARTICLE_PRINTED: 'Article Printed',
  BLOG_CATEGORY_VIEWED: 'Blog Category Viewed',
  BLOG_POST_VIEWED: 'Blog Post Viewed',
  BLOG_POST_READ: 'Blog Post Read',
  BROWN_SPOT_RESULTS_VIEWED: 'Brown Spot Results Viewed',
  CANCELLATION_STARTED: 'Cancellation Started',
  CART_VIEWED: 'Cart Viewed',
  CHATBOT_FEEDBACK_SUBMITTED: 'Chatbot Feedback Submitted',
  CHATBOT_MESSAGE_SENT: 'Chatbot Message Sent',
  CHATBOT_OPENED: 'Chatbot Opened',
  CHECKOUT_STARTED: 'Checkout Started',
  CHECKOUT_STEP_COMPLETED: 'Checkout Step Completed',
  FILTER_CLICKED: 'Filter Clicked',
  LAWN_ANALYSIS_VIEWED: 'Lawn Analysis Viewed',
  LAWN_SIZE_ESTIMATED: 'Lawn Size Estimated',
  LAWN_SIZE_UPDATED: 'Lawn Size Updated',
  ORDER_COMPLETED: 'Order Completed',
  PAGE_VIEWED: 'Page Viewed',
  PASSWORD_CREATED: 'Password Created',
  PASSWORD_UPDATED: 'Password Updated',
  PLAN_PURCHASED: 'Plan Purchased',
  PLAN_TIER_RECOMMENDED: 'Plan Tier Recommended',
  PLAN_TIER_SELECTED: 'Plan Tier Selected',
  PROMO_BANNER_CODE_COPIED: 'Promo Banner Code Copied',
  SURVEY_COMPLETED: 'Survey Completed',
  SURVEY_STARTED: 'Survey Started',
  VIDEO_PLAYED: 'Video Played',
  PRODUCT_ADDED: 'Product Added',
  PRODUCT_CLICKED: 'Product Clicked',
  PRODUCT_LIST_VIEWED: 'Product List Viewed',
  PRODUCT_PURCHASED: 'Product Purchased',
  PRODUCT_VIEWED: 'Product Viewed',
  QUESTIONNAIRE_ANSWER_SELECTED: 'Questionnaire Answer Selected',
  QUESTIONNAIRE_COMPLETED: 'Questionnaire Completed',
  SEED_FINDER_RESULTS_VIEWED: 'Seed Finder Results Viewed',
  SESSION_CREATED: 'Session Created',
  TASK_UPDATED: 'Task Updated',
  USER_SIGN_IN: 'User Sign In',
  USER_SIGN_OUT: 'User Sign Out',
  LAWN_PROBLEM_SELECTED: 'Lawn Problem Selected',
  YARD_ISSUE_SELECTED: 'Yard Issue Selected',
  TOOLTIP_VIEWED: 'Tooltip Viewed',
  VWO_FULLSTACK_EXPERIMENT: 'VWO Fullstack Experiment',
  ZIPCODE_LAWN_SIZE_FORM_SUBMITTED: 'Zip Code Lawn Size Form Submitted',

  // One-off Google Ads events
  PRO_SERVICE_PURCHASED: 'Pro Service Purchased',
};
