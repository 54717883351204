import { AnalyticsBrowser } from '@segment/analytics-next';
import Cookies from 'js-cookie';

import { getPestPlanType } from '~/components/pest/bundles/helpers';
import {
  ALGOLIA_ARTICLES_INDEX,
  ALGOLIA_PRODUCTS_INDEX,
} from '~/components/search/shared';
import { subscriptionType } from '~/features/subscriptions/subscription';
import { cartPurchaseOrderSelector } from '~/features/cart/cart';
import { sundayStoreProductDetailsRoute } from '~/routes';
import { urlFor } from '~/sanity/images';
import { EventType, DeprecatedEventType } from '~/utils/analytics';
import { centsToFloat } from '~/utils/numbers';

export const analytics = AnalyticsBrowser.load({
  writeKey: window.env.SEGMENT_WRITE_KEY,
});

let _email = '';

analytics.addSourceMiddleware(({ payload, next }) => {
  const event = payload.obj;
  const vwoUuid = Cookies.get('_vwo_uuid');
  switch (event.type) {
    case 'track':
    case 'page':
      payload.obj.properties.vwo_uuid = vwoUuid;
      payload.obj.properties.email = _email;
      break;
    case 'identify':
      payload.obj.traits.vwo_uuid = vwoUuid;
      break;
    default:
  }
  next();
});

export const segmentPlugin = {
  name: 'Segment',
  init: () => {},
  events: {
    [EventType.PAGE_VIEWED]: () => {
      analytics.page({
        browser: window.navigator.userAgent,
      });
    },

    [EventType.SESSION_CREATED]: ({ email, userUuid }) => {
      analytics.identify(userUuid, { email });
      _email = email;
    },

    [EventType.USER_SIGN_IN]: ({ email, userUuid, provider }) => {
      analytics.track(EventType.USER_SIGN_IN, {
        email,
        provider,
      });
      analytics.identify(userUuid, { email });
    },

    [EventType.USER_SIGN_OUT]: () => {
      analytics.reset();
      _email = '';
    },

    [DeprecatedEventType.EXPERIMENT]: (experimentName, variant) => {
      analytics.track(DeprecatedEventType.EXPERIMENT, {
        experiment: experimentName,
        variant,
      });
    },

    [EventType.ACCOUNT_CREATED]: ({
      email,
      source,
      userSlug,
      userUuid,
      provider,
    }) => {
      analytics.track(EventType.ACCOUNT_CREATED, {
        email,
        creation_method: source,
        user_slug: userSlug,
        created_at: new Date().toISOString(),
        provider,
      });

      analytics.identify(userUuid, { user_slug: userSlug, email });
    },

    [EventType.ACCOUNT_UPDATED]: ({
      userUuid,
      city,
      country,
      email,
      firstName,
      formattedAddress,
      lastName,
      state,
      zip,
      phoneNumber,
    }) => {
      analytics.track(EventType.ACCOUNT_UPDATED, {
        address: formattedAddress,
        city,
        country,
        email,
        first_name: firstName,
        last_name: lastName,
        name: `${firstName} ${lastName}`,
        phone_number: phoneNumber,
        state,
        zip,
      });

      analytics.identify(userUuid, {
        email,
        first_name: firstName,
        last_name: lastName,
        name: `${firstName} ${lastName}`,
        phone_number: phoneNumber,
        address_billing: formattedAddress,
        city_billing: city,
        country_billing: country,
        state_billing: state,
        zip_billing: zip,
      });
    },

    [EventType.ADDRESS_CTA_CLICKED]: ({ url, inputId, type }) => {
      analytics.track(EventType.ADDRESS_CTA_CLICKED, {
        sunday_page_url: url,
        input_id: inputId,
        type,
      });
    },

    [EventType.ADDRESS_ENTERED]: ({ lawn }) => {
      analytics.track(EventType.ADDRESS_ENTERED, {
        lawn_uuid: lawn.uuid,
        address: lawn.address.formatted,
        city: lawn.address.city,
        state: lawn.address.stateCode,
        zip: lawn.address.postalCode,
        country: lawn.address.countryCode,
        rain_type: lawn.climate.rainType,
        temp_type: lawn.climate.temperatureType,
        grass_type: lawn.climate.grassType,
        ph_type: lawn.soil.phType,
        soil_type: lawn.soil.soilType,
        size: lawn.size.sqFt,
        page_url: window.location.href,
      });
    },

    [EventType.LAWN_SIZE_UPDATED]: ({ lawn, userUuid, newLawnSize }) => {
      analytics.track(EventType.LAWN_SIZE_UPDATED, {
        user_uuid: userUuid,
        lawn_uuid: lawn.uuid,
        size: newLawnSize,
        previous_size: lawn.size.sqFt,
      });
      analytics.identify(userUuid, {
        size: lawn.size.sqFt,
      });
    },

    [EventType.LAWN_SIZE_ESTIMATED]: ({ lawn }) => {
      analytics.track(EventType.LAWN_SIZE_ESTIMATED, {
        lawn_uuid: lawn.uuid,
        address: lawn.address.formatted,
        city: lawn.address.city,
        size: lawn.size.sqFt,
        state: lawn.address.stateCode,
        zip: lawn.address.postalCode,
      });
    },

    [EventType.CHECKOUT_STARTED]: ({ cart, addOnProductsList }) => {
      const products = cart.contents.map((cartItem) => {
        const productDetails = addOnProductsList.find(
          (product) => product.sku === cartItem.sku.skuId
        );

        return {
          category: productDetails?.categories?.[0]?.category?.name,
          subcategory: productDetails?.categories?.[0]?.subCategory?.name,
          image_url: productDetails
            ? urlFor(productDetails.primaryImage).width(400).url()
            : getCartItemImageUrl(cartItem),
          name: cartItem.sku.title,
          price: centsToFloat(cartItem.unitPrice),
          sku: cartItem.sku.skuId,
          quantity: cartItem.quantity,
          value: cartItem.unitPrice * cartItem.quantity,
          url: productDetails
            ? `https://www.getsunday.com${sundayStoreProductDetailsRoute({
                category: productDetails?.categories?.[0]?.category?.slug,
                slug: productDetails.slug,
              })}`
            : 'https://www.getsunday.com',
        };
      });

      analytics.track(EventType.CHECKOUT_STARTED, {
        order_uuid: cart.uuid,
        coupon: cart.coupon?.code || '',
        discount: centsToFloat(cart.coupon?.value || 0),
        value: centsToFloat(cartPurchaseOrderSelector(cart).subtotal),
        products,
      });
    },

    [EventType.CHECKOUT_STEP_COMPLETED]: ({ stepName, stepNumber }) => {
      analytics.track(EventType.CHECKOUT_STEP_COMPLETED, {
        step_name: stepName,
        step_number: stepNumber,
      });
    },

    [EventType.ORDER_COMPLETED]: ({
      cart,
      addOnProductsList,
      customerName,
    }) => {
      // Product Purchased should only be fired for products purchased outside of a plan/subscription
      const cartItems = cart.contents.filter((cartItem) => {
        const isPestOrLawnSubscriptionItem = [
          subscriptionType.LAWN_PLAN,
          subscriptionType.MOSQUITO,
          subscriptionType.TOTAL_HOME,
          subscriptionType.TICK,
        ].includes(cartItem.sku.subscriptionType);

        const isPartOfBundle = Boolean(cartItem.bundleUuid);

        return !isPestOrLawnSubscriptionItem && !isPartOfBundle;
      });

      const products = cart.contents.map((cartItem) => {
        const productDetails = addOnProductsList.find(
          (product) => product.sku === cartItem.sku.skuId
        );

        // Add objectID to each product for Algolia per Segment's spec
        // https://segment.com/docs/connections/destinations/catalog/actions-algolia-insights/#track

        // Some cart items (mostly individual purchase skus part of a bundle)
        // don't have product details returned from the BFF because they are
        // marked as unsearchable in CT/Algolia. We can safely ignore these items
        if (!productDetails?.id) {
          return cartItem;
        }

        return {
          ...cartItem,
          objectID: productDetails.id,
        };
      });

      analytics.track(EventType.ORDER_COMPLETED, {
        coupon: cart.coupon?.code || '',
        currency: 'USD',
        discount: centsToFloat(cart.coupon?.value || 0),
        revenue_client: centsToFloat(cart.subtotal - cart.taxAmount),
        order_uuid: cart.uuid,
        products,
        tax: centsToFloat(cart.taxAmount),
        total: centsToFloat(cart.totalPrice),
        payment_method: cart.paymentDetails.sourceType,

        // Algolia-related fields
        index: ALGOLIA_PRODUCTS_INDEX,
        eventType: 'conversion',
      });

      cartItems.forEach((cartItem) => {
        const productDetails = addOnProductsList.find(
          (product) => product.sku === cartItem.sku.skuId
        );

        analytics.track(EventType.PRODUCT_PURCHASED, {
          order_uuid: cart.uuid,
          customer_name: customerName,
          order_date: new Date().toISOString(),
          product_url: productDetails
            ? `https://www.getsunday.com${sundayStoreProductDetailsRoute({
                category: productDetails?.categories?.[0]?.category?.slug,
                slug: productDetails.slug,
              })}`
            : '',
          coupon: cart.coupon?.code || '',
          category: productDetails?.categories?.[0]?.category?.name,
          subcategory: productDetails?.categories?.[0]?.subCategory?.name,
          quantity: cartItem.quantity,
          sku: cartItem.sku.skuId,
          currency: 'USD',
          discount: centsToFloat(cart.coupon?.value || 0),
          price: centsToFloat(cartItem.unitPrice),
          product_id: productDetails?.id,
          plan_item: Boolean(cartItem.bundleUuid),
          product_name: productDetails?.name,
          value: centsToFloat(cartItem.unitPrice * cartItem.quantity),
          image_url: productDetails
            ? urlFor(productDetails.primaryImage).width(400).url()
            : getCartItemImageUrl(cartItem),
          cartSubtotal: cart.subtotal,
          cartTotalPriceWithTax: cart.totalPriceWithTax,
        });
      });
    },

    [EventType.PRODUCT_ADDED]: ({
      productId,
      cartUuid,
      currency = 'USD',
      image,
      imageUrl,
      price,
      productName,
      quantity,
      sku,
      isFeaturedProduct,
      cartValue,
      cartContents,
      products,
      productDetails,
    }) => {
      const image_url = imageUrl ? imageUrl : urlFor(image).width(400).url();
      const value = centsToFloat(price * quantity);

      const itemNames = cartContents
        .filter((item) => !item.bundleUuid)
        .map((item) => {
          const productDetails = products.find(
            (product) => item.sku.skuId === product.purchaseSku.skuId
          );

          return productDetails?.name || item.sku.title;
        });

      const cartItems = cartContents
        .filter((item) => !item.bundleUuid)
        .map((item) => {
          const productInCart = products?.find(
            (product) => product.purchaseSku.skuId === item.sku.skuId
          );

          return {
            product_id: productInCart?.productDetails?.id,
            sku: productInCart?.purchaseSku?.skuId,
            product_name: productInCart?.productDetails?.name,
            quantity: Number(item.quantity),
            price: centsToFloat(item.unitPrice),
            total: centsToFloat(item.totalPrice),
            product_url: productInCart?.productDetails
              ? `https://www.getsunday.com${sundayStoreProductDetailsRoute({
                  category:
                    productInCart?.productDetails?.categories?.[0]?.category
                      ?.slug,
                  slug: productInCart?.productDetails?.slug,
                })}`
              : ``,
            image_url: urlFor(productInCart?.productDetails?.primaryImage)
              .width(400)
              .url(),
            product_category:
              productInCart?.productDetails?.categories?.[0]?.category?.name,
            product_subcategory:
              productInCart?.productDetails?.categories?.[0]?.subCategory?.name,
            product_description:
              productInCart?.productDetails?.shortDescription,
          };
        });

      analytics.track(EventType.PRODUCT_ADDED, {
        cart_uuid: cartUuid,
        currency,
        image_url,
        quantity,
        sku,
        category: productDetails?.categories?.[0]?.category?.name,
        subcategory: productDetails?.categories?.[0]?.subCategory?.name,
        value,
        price: centsToFloat(price),
        product_id: productId,
        product_name: productName,
        product_url: productDetails
          ? `https://www.getsunday.com${sundayStoreProductDetailsRoute({
              category: productDetails?.categories?.[0]?.category?.slug,
              slug: productDetails?.slug,
            })}`
          : '',
        cart_value: centsToFloat(cartValue),
        cart_items: cartItems,
        cart_item_names: itemNames,
        is_featured_product: isFeaturedProduct,

        // Algolia-related fields
        index: ALGOLIA_PRODUCTS_INDEX,
        eventType: 'conversion',
        objectID: productId,
      });
    },

    [EventType.CART_VIEWED]: ({ cart, allProducts, lawn }) => {
      const productsInCart =
        cart.contents.length > 0
          ? cart.contents
              .filter((cartItem) => !cartItem.bundleUuid)
              .map((item) => {
                const productInCart = allProducts?.find(
                  (product) => product.purchaseSku.skuId === item.sku.skuId
                );

                return {
                  product_id: productInCart?.productDetails?.id,
                  sku: productInCart?.purchaseSku?.skuId,
                  category:
                    productInCart?.productDetails?.categories?.[0]?.category
                      ?.name,
                  subcategory:
                    productInCart?.productDetails?.categories?.[0]?.subCategory
                      ?.name,
                  name:
                    productInCart?.productDetails?.name ||
                    productInCart?.purchaseSku?.title,
                  price: centsToFloat(item.unitPrice),
                  quantity: Number(item.quantity),
                  coupon: cart.coupon,
                  position: cart.contents.indexOf(item) + 1,
                  url: productInCart
                    ? `https://www.getsunday.com${sundayStoreProductDetailsRoute(
                        {
                          category:
                            productInCart?.productDetails?.categories?.[0]
                              ?.category?.slug,
                          slug: productInCart?.productDetails?.slug,
                        }
                      )}`
                    : '',
                  image_url: urlFor(productInCart?.productDetails?.primaryImage)
                    .width(400)
                    .url(),
                };
              })
          : [];

      if (cart.containsLawnPlan) {
        const lawnPlanItems = cart.contents.filter(
          (cartItem) => cartItem.bundleUuid !== null
        );

        const selectedCart = cartPurchaseOrderSelector(cart);

        productsInCart.push({
          product_id: lawnPlanItems[0].bundleUuid,
          sku: cart.planSku,
          category: 'Smart Lawn Plan',
          name: cart.bundleTitle,
          price: centsToFloat(selectedCart.bundlePrice),
          quantity: 1,
          coupon: cart.coupon,
          url: `https://www.getsunday.com/lawns/${lawn?.uuid}/lawn-plan`,
          image_url:
            'https://www.getsunday.com/images/unboxing-bottom-400w.png?v1',
        });
      }

      analytics.track(EventType.CART_VIEWED, {
        cart_id: cart.uuid,
        products: productsInCart,
      });
    },

    [EventType.PRODUCT_LIST_VIEWED]: ({
      page,
      categoryName,
      subcategoryName,
      pageUrl,
      itemNames,
    }) => {
      analytics.track(EventType.PRODUCT_LIST_VIEWED, {
        page,
        category_name: categoryName,
        subcategory_name: subcategoryName,
        page_url: pageUrl,
        item_names: itemNames,
      });
    },

    [EventType.SEED_FINDER_RESULTS_VIEWED]: ({
      email,
      itemNames,
      products,
      lawnTrouble,
    }) => {
      analytics.track(EventType.SEED_FINDER_RESULTS_VIEWED, {
        email,
        item_names: itemNames,
        products,
        lawn_trouble: lawnTrouble,
      });
    },

    [EventType.BROWN_SPOT_RESULTS_VIEWED]: ({ diagnoses }) => {
      analytics.track(EventType.BROWN_SPOT_RESULTS_VIEWED, {
        diagnoses,
      });
    },

    [EventType.PRODUCT_VIEWED]: ({
      sku,
      name,
      price,
      category,
      queryID,
      objectID,
      productId,
      productUrl,
      imageUrl,
      query,
    }) => {
      analytics.track(EventType.PRODUCT_VIEWED, {
        sku,
        name,
        price: centsToFloat(price),
        category,
        eventType: 'view',
        index: ALGOLIA_PRODUCTS_INDEX,
        queryID,
        objectID,
        query,
        product_id: productId,
        url: productUrl ? `https://www.getsunday.com${productUrl}` : '',
        image_url: urlFor(imageUrl).width(400).url(),
      });
    },

    [EventType.QUESTIONNAIRE_ANSWER_SELECTED]: ({
      formName,
      formSlug,
      formValues,
    }) => {
      analytics.track(EventType.QUESTIONNAIRE_ANSWER_SELECTED, {
        form_name: formName,
        form_slug: formSlug,
        ...formValues,
      });
    },

    [EventType.QUESTIONNAIRE_COMPLETED]: ({
      lawnUuid,
      userUuid,
      userSlug,
      formName,
      formSlug,
      formValues,
    }) => {
      analytics.track(EventType.QUESTIONNAIRE_COMPLETED, {
        lawn_uuid: lawnUuid,
        form_name: formName,
        form_slug: formSlug,
        user_slug: userSlug,
        ...formValues,
      });

      analytics.identify(userUuid, {
        ...formValues,
      });
    },

    [EventType.PLAN_TIER_SELECTED]: ({
      lawnUuid,
      cartUuid,
      planCode,
      planTier,
      planSku,
      planPrice,
      flexPayFirstBoxPrice = null,
      pouchCount,
      cartContents,
      products,
      paymentType,
      numberOfShipments,
      isRecommended,
    }) => {
      // Get non-bundle items in cart to display in cart_item analytics field
      const nonBundleItems = cartContents
        // Filter out lawn bundles
        .filter((item) => !item.bundleUuid)
        // Filter out pest bundles because it's unreasonably difficult to get the category, image data and PDP URLs for them
        .filter((item) => getPestPlanType(item.sku.skuId) === null);

      const cartItemNames = nonBundleItems.map((item) => {
        const productDetails = products.find(
          (product) => item.sku.skuId === product.purchaseSku.skuId
        );

        return productDetails?.name || item.sku.title;
      });

      const cartItems = nonBundleItems.map((item) => {
        const productInCart = products.find(
          (product) => product.purchaseSku.skuId === item.sku.skuId
        );

        const category =
          productInCart?.productDetails?.categories?.[0]?.category?.name;

        const subcategory =
          productInCart?.productDetails?.categories?.[0]?.subCategory?.name;

        return {
          product_id: productInCart?.productDetails?.id,
          sku: productInCart?.purchaseSku?.skuId,
          product_name: productInCart?.productDetails?.name,
          quantity: Number(item.quantity),
          price: centsToFloat(item.unitPrice),
          total: centsToFloat(item.totalPrice),
          product_url: productInCart?.productDetails
            ? `https://www.getsunday.com${sundayStoreProductDetailsRoute({
                category:
                  productInCart?.productDetails?.categories?.[0]?.category
                    ?.slug,
                slug: productInCart?.productDetails?.slug,
              })}`
            : ``,
          image_url: urlFor(productInCart?.productDetails?.primaryImage)
            .width(400)
            .url(),
          product_categories: [category],
          product_subcategories: [subcategory],
          product_description:
            productInCart?.productDetails?.shortDescription?.[0]?.children?.[0]
              ?.text,
        };
      });

      analytics.track(EventType.PLAN_TIER_SELECTED, {
        lawn_uuid: lawnUuid,
        cart_uuid: cartUuid,
        plan_sku: planSku,
        plan_code: planCode,
        plan_tier: planTier,
        plan_price: centsToFloat(planPrice),
        flexpay_price: centsToFloat(flexPayFirstBoxPrice || planPrice),
        plan_payment_type: paymentType,
        pouch_count: pouchCount,
        cart_items: cartItems,
        cart_item_names: cartItemNames,
        number_of_shipments: numberOfShipments,
        is_recommended: isRecommended,
      });
    },

    [EventType.PLAN_TIER_RECOMMENDED]: ({
      flexPayFirstBoxPrice = null,
      planName,
      planPrice,
      planSku,
      planUuid,
    }) => {
      analytics.track(EventType.PLAN_TIER_RECOMMENDED, {
        plan_name: planName,
        plan_price: centsToFloat(planPrice),
        flexpay_price: centsToFloat(flexPayFirstBoxPrice || planPrice),
        plan_sku: planSku,
        plan_uuid: planUuid,
      });
    },

    [EventType.PLAN_PURCHASED]: ({
      orderUuid,
      userUuid,
      planPrice,
      planName,
      planSku,
      planPurchaseDate,
      planCode,
      isFlexPay = false,
      cartSubtotal,
      cartTotalPriceWithTax,
    }) => {
      analytics.track(EventType.PLAN_PURCHASED, {
        order_uuid: orderUuid,
        plan_code: planCode,
        plan_sku: planSku,
        plan_billing_amount: centsToFloat(planPrice),
        plan_name: planName,
        plan_purchase_date: planPurchaseDate,
        plan_payment_type: isFlexPay ? 'flex-pay' : 'annual',
        cartSubtotal,
        cartTotalPriceWithTax,
      });

      analytics.identify(userUuid, {
        lawn_plan_billing_amount: centsToFloat(planPrice),
        lawn_plan_name: planName,
        lawn_plan_status: 'active',
        lawn_plan_purchase_date: planPurchaseDate,
      });
    },

    [EventType.BLOG_CATEGORY_VIEWED]: ({ blogCategory }) => {
      analytics.track(EventType.BLOG_CATEGORY_VIEWED, {
        blog_category: blogCategory,
      });
    },

    [EventType.BLOG_POST_VIEWED]: ({
      blogCategory,
      blogSubCategory,
      blogPostAuthor,
      blogPostTitle,
      blogTags,
      blogUrl,
    }) => {
      analytics.track(EventType.BLOG_POST_VIEWED, {
        blog_category: blogCategory,
        blog_sub_category: blogSubCategory,
        blog_post_author: blogPostAuthor,
        blog_post_title: blogPostTitle,
        blog_tags: blogTags,
        url: blogUrl,
      });
    },

    [EventType.ARTICLE_PRINTED]: ({ category, subCategory, title }) => {
      analytics.track(EventType.ARTICLE_PRINTED, {
        article_category: category,
        article_sub_category: subCategory,
        article_title: title,
      });
    },

    [EventType.LAWN_ANALYSIS_VIEWED]: ({ lawn, userUuid }) => {
      analytics.track(EventType.LAWN_ANALYSIS_VIEWED, {
        lawn_uuid: lawn.uuid,
        address: lawn.address.formatted,
        city: lawn.address?.city,
        state: lawn.address?.stateCode,
        zip: lawn.address?.postalCode,
        country: lawn.address?.countryCode,
        rain_type: lawn.climate?.rainType,
        temp_type: lawn.climate?.temperatureType,
        grass_type: lawn.climate?.grassType,
        ph_type: lawn.soil?.phType,
        soil_type: lawn.soil?.soilType,
        size: lawn.size.sqFt,
      });

      analytics.identify(userUuid, {
        lawn_uuid: lawn.uuid,
        address_yard: lawn.address.formatted,
        city_yard: lawn.address?.city,
        state_yard: lawn.address?.stateCode,
        zip_yard: lawn.address?.postalCode,
        country_yard: lawn.address?.countryCode,
        rain_type: lawn.climate?.rainType,
        temp_type: lawn.climate?.temperatureType,
        grass_type: lawn.climate?.grassType,
        ph_type: lawn.soil?.phType,
        soil_type: lawn.soil?.soilType,
        size: lawn.size.sqFt,
      });
    },

    [EventType.PASSWORD_CREATED]: ({ creation_method, email, userUuid }) => {
      analytics.track(EventType.PASSWORD_CREATED, {
        creation_method,
        email,
        userUuid,
      });
    },

    [EventType.PASSWORD_UPDATED]: ({ creation_method, email, userUuid }) => {
      analytics.track(EventType.PASSWORD_UPDATED, {
        creation_method,
        email,
        userUuid,
      });
    },

    [EventType.PRODUCT_CLICKED]: ({
      name,
      query,
      queryID,
      objectID,
      position,
      sku,
      filters,
    }) => {
      analytics.track(EventType.PRODUCT_CLICKED, {
        name,
        sku,
        query,
        index: ALGOLIA_PRODUCTS_INDEX,
        queryID,
        objectID,
        position,
        eventType: 'click',
        filters,
      });
    },

    [EventType.ARTICLE_CLICKED]: ({
      title,
      query,
      queryID,
      objectID,
      position,
    }) => {
      analytics.track(EventType.ARTICLE_CLICKED, {
        title,
        query,
        index: ALGOLIA_ARTICLES_INDEX,
        queryID,
        objectID,
        position,
        eventType: 'click',
      });
    },

    [EventType.BLOG_POST_READ]: ({
      blogCategory,
      blogSubCategory,
      blogPostAuthor,
      blogPostTitle,
      blogTags = [],
      blogUrl,
      query,
      queryID,
      objectID,
      position,
    }) => {
      analytics.track(EventType.BLOG_POST_READ, {
        blog_category: blogCategory,
        blog_sub_category: blogSubCategory,
        blog_post_author: blogPostAuthor,
        blog_post_title: blogPostTitle,
        blog_tags: blogTags.map((tag) => tag.name),
        url: `https://www.getsunday.com${blogUrl}`,
        query,
        index: ALGOLIA_ARTICLES_INDEX,
        queryID,
        objectID,
        position,
        eventType: 'conversion',
      });
    },

    [EventType.FILTER_CLICKED]: ({
      filterName,
      attribute,
      indexName,
      query,
      queryID,
    }) => {
      analytics.track(EventType.FILTER_CLICKED, {
        filter_name: filterName,
        attribute,
        index: indexName,
        query,
        queryID,
      });
    },

    [EventType.TASK_UPDATED]: ({
      taskStatus,
      taskTitle,
      email,
      userUuid,
      planTitle,
      planType,
      purchaseSku,
    }) => {
      analytics.track(EventType.TASK_UPDATED, {
        task_status: taskStatus,
        task_title: taskTitle,
        email,
        user_uuid: userUuid,
        plan_title: planTitle,
        plan_type: planType,
        purchase_sku: purchaseSku,
      });
    },

    [EventType.VIDEO_PLAYED]: (videoName) => {
      analytics.track(EventType.VIDEO_PLAYED, {
        category: 'Video',
        action: 'Play',
        label: videoName,
      });
    },
    [EventType.SURVEY_STARTED]: ({ surveyName }) => {
      analytics.track(EventType.SURVEY_STARTED, {
        survey_name: surveyName,
      });
    },
    [EventType.SURVEY_COMPLETED]: ({ surveyName, leadSource, otherText }) => {
      analytics.track(EventType.SURVEY_COMPLETED, {
        survey_name: surveyName,
        lead_source: leadSource,
        other_text: otherText,
      });
    },
    [EventType.CANCELLATION_STARTED]: ({ email, subscription_type }) => {
      analytics.track(EventType.CANCELLATION_STARTED, {
        email,
        subscription_type,
      });
    },

    [EventType.CHATBOT_MESSAGE_SENT]: ({
      userMessage,
      chatbotMessage,
      userUuid,
      model,
      imageCount,
    }) => {
      analytics.track(EventType.CHATBOT_MESSAGE_SENT, {
        user_message: userMessage,
        chatbot_message: chatbotMessage,
        user_uuid: userUuid,
        model,
        image_count: imageCount,
      });
    },

    [EventType.CHATBOT_OPENED]: ({ model }) => {
      analytics.track(EventType.CHATBOT_OPENED, {
        model,
      });
    },

    [EventType.CHATBOT_FEEDBACK_SUBMITTED]: ({
      messageUuid,
      isHelpful,
      sessionId,
      imageCount,
    }) => {
      analytics.track(EventType.CHATBOT_FEEDBACK_SUBMITTED, {
        message_uuid: messageUuid,
        is_helpful: isHelpful,
        session_id: sessionId,
        image_count: imageCount,
      });
    },

    [EventType.LAWN_PROBLEM_SELECTED]: ({ problemSlug, problemLabel }) => {
      analytics.track(EventType.LAWN_PROBLEM_SELECTED, {
        problem_slug: problemSlug,
        problem_label: problemLabel,
      });
    },

    [EventType.YARD_ISSUE_SELECTED]: ({ selection }) => {
      analytics.track(EventType.YARD_ISSUE_SELECTED, {
        yard_issue: selection,
      });
    },

    [EventType.VWO_FULLSTACK_EXPERIMENT]: ({ campaignKey, vwoVariant }) => {
      analytics.track(EventType.VWO_FULLSTACK_EXPERIMENT, {
        campaignKey,
        vwoVariant,
      });
    },

    [EventType.PROMO_BANNER_CODE_COPIED]: ({ coupon, pathname }) => {
      analytics.track(EventType.PROMO_BANNER_CODE_COPIED, {
        coupon,
        pathname,
      });
    },

    [EventType.TOOLTIP_VIEWED]: ({ content, eventData }) => {
      analytics.track(EventType.TOOLTIP_VIEWED, {
        content,
        ...eventData,
      });
    },

    [EventType.ZIPCODE_LAWN_SIZE_FORM_SUBMITTED]: (formValues) => {
      analytics.track(EventType.ZIPCODE_LAWN_SIZE_FORM_SUBMITTED, {
        ...formValues,
      });
    },
  },
};

const DEFAULT_PLAN_IMAGE =
  'https://www.getsunday.com/images/unboxing-bottom-400w.png?v1';
const DEFAULT_ITEM_IMAGE = 'https://www.getsunday.com/images/logo-black.svg?v1';

/**
 * TODO: This is a temporary helper function used for sending a product's image url to Segment
 *       and should be removed once we have a proper solution for unifying product images (sanity vs subsoil)
 */
const getCartItemImageUrl = (cartItem) => {
  return (
    cartItem.sku.imageUrl ||
    (cartItem.sku.isPlan ? DEFAULT_PLAN_IMAGE : DEFAULT_ITEM_IMAGE)
  );
};
